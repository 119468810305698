import store from "@/state/store";

export default [
  {
    path: "/",
    name: "dashboard",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/dashboard"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/adminProfile"),
  },
  {
    path: "/client",
    name: "Client",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/client"),
  },
  {
    path: "/add-client",
    name: "addClient",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addClient"),
  },
  {
    path: "/users",
    name: "Users",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/users/index"),
  },
  {
    path: "/add-user",
    name: "addUser",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/users/save"),
  },
  {
    path: "/edit-user/:id",
    name: "editUser",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/users/save"),
  },
  {
    path: "/single-user/:id",
    name: "singleUser",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/users/show"),
  },
  {
    path: "/add-story/:id?",
    name: "addStory",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addStories"),
  },
  {
    path: "/add-journal-story/:id?",
    name: "addJournalStory",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addJournalStory"),
  },
  {
    path: "/edit-journal-story/:id?",
    name: "edit_journalStory",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addJournalStory"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/client-login",
    name: "clientLogin",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-reset-password/:token",
    name: "Forgot reset password",
    component: () => import("./views/account/forgot-reset-password"),
    props: true,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        next(
          authRequiredOnPreviousRoute ? { name: "dashboard" } : { ...routeFrom }
        );
      },
    },
  },


  {
    path: "/single-client/:id",
    name: "single_client",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/single_client"),
  },
  {
    path: "/edit-client/:id",
    name: "edit_client",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addClient"),
  },
  {
    path: "/stories",
    name: "stories",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/stories"),
  },
  {
    path: "/default-task",
    name: "Default_Task",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/defaultTask"),
  },
  {
    path: "/single-story/:id",
    name: "single-story",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/single_story"),
  },
  {
    path: "/edit-story/:id",
    name: "editStory",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addStories"),
  },
  {
    path: "/add-journal",
    name: "Add Journal",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addJournal"),
  },
  {
    path: "/journals",
    name: "journals",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/journals"),
  },
  {
    path: "/manage-journals",
    name: "manage journals",
    meta: { authRequired: true, admin:true },
    component: () => import("./views/manage-journals"),
  },
  {
    path: "/single-journal/:id?",
    name: "single_journal",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/single_journal"),
  },
  {
    path: "/edit-journal/:id",
    name: "edit_journal",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addJournal"),
  },
  {
    path: "/all-task",
    name: "allTask",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/allTask"),
  },
  {
    path: "/add-more-journal/:id",
    name: "addMoreJournal",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addMoreJournal"),
  },
  {
    path: "/journal-story/:id",
    name: "Journal Story",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/journalStory"),
  },
  {
    path: "/all-response",
    name: "allResponse",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/allResponse"),
  },
  {
    path: "/pending-payments",
    name: "pendingPayments",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/pendingPayments"),
  },
  {
    path: "/all-orders",
    name: "orders",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/invoices"),
  },
  {
    path: "/services",
    name: "services",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/editServices"),
  },
  {
    path: "/coupons",
    name: "coupons",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/coupons"),
  },
  {
    path: "/email-note",
    name: "emailNote",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/note"),
  },
  {
    path: "/sign-up",
    name: "signUp",
    component: () => import("./views/signUp"),
  },
  {
    path: "/add-new-story/:id",
    name: "add-new-story",
    meta: { 
      authRequired: true , 
    },
    component: () => import("./views/signUp"),
  },
  {
    path: "/super-sign-up",
    name: "superSignUp",
    component: () => import("./views/superSignUp"),
  },
  {
    path: "/payment/:id",
    name: "payment",
    meta: { 
      authRequired: true , 
      payment:true
    },
    component: () => import("./views/payment"),
  },
  {
    path: "/add-on-payment",
    name: "add-on-payment",
    meta: { 
      authRequired: true , 
      payment:true
    },
    component: () => import("./views/payment"),
  },
  //----Client-Portal-routing
  {
    path: "/dashboard",
    name: "dashboardClient",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/dashboard"),
  },
  {
    path: "/submission-result",
    name: "storySubmissionResult",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/storySubmissionResult"),
  },
  {
    path: "/add-story",
    name: "addStoryClient",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/addStory"),
  },
  {
    path: "/add-on-service",
    name: "add-on-service",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/addOnService"),
  },
  {
    path: "/edit-client-story/:id",
    name: "editClientStory",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/addStory"),
  },
  {
    path: "/stories-listing",
    name: "storiesClient",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/storyListing"),
  },
  {
    path: "/story-information/:id",
    name: "single_storyClient",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/singleStory"),
  },
  {
    path: "/single-journal",
    name: "single_journalClient",
    meta: { authRequired: true },
    component: () => import("../components/client/singleJournal"),
  },
  {
    path: "/setting",
    name: "settingClient",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/setting"),
  },
  {
    path: "/orders",
    name: "ordersClient",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/invoice"),
  },
  {
    path: "/pending-payment",
    name: "pendingPayment",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/pendingPayment"),
  },
  {
    path: "/payment-history",
    name: "paymentHistory",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/activeSubscription"),
  },
  {
    path: "/change-password",
    name: "changePassword",
    meta: { authRequired: true, admin: false },
    component: () => import("../components/client/changePassword"),
  },

  //  Add Extra Services
  {
    path: "/add-extra-services",
    name: "addExtraServices",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/addExtraServices"),
  },
  // Ckeditor
  {
    path: "/email-template",
    name: "emailTemplate",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/emailTemplates"),
  },
  {
    path: "/edit-email-template/:id",
    name: "editEmailTemplate",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/ckEditor"),
  },
  // Theme-routing-below
  {
    path: "/invoices/detail",
    name: "Invoice Detail",
    meta: { authRequired: true },
    component: () => import("./views/invoices/detail"),
  },
  {
    path: "/invoices/list",
    name: "Invoice List",
    meta: { authRequired: true },
    component: () => import("./views/invoices/list"),
  },
  {
    path: "/pages/starter",
    name: "Starter",
    meta: { authRequired: true },
    component: () => import("./views/utility/starter"),
  },
  {
    path: "/pages/maintenance",
    name: "Maintenance",
    meta: { authRequired: true },
    component: () => import("./views/utility/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { authRequired: true },
    component: () => import("./views/utility/coming-soon"),
  },
  {
    path: "/pages/timeline",
    name: "Timeline",
    meta: { authRequired: true },
    component: () => import("./views/utility/timeline"),
  },
  {
    path: "/pages/faqs",
    name: "FAQs",
    meta: { authRequired: true },
    component: () => import("./views/utility/faqs"),
  },
  {
    path: "/pages/pricing",
    name: "Pricing",
    meta: { authRequired: true },
    component: () => import("./views/utility/pricing"),
  },
  {
    path: "/pages/404",
    name: "Error-404",
    meta: { authRequired: true },
    component: () => import("./views/utility/404"),
  },
  {
    path: "/pages/500",
    name: "Error-500",
    meta: { authRequired: true },
    component: () => import("./views/utility/500"),
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  {
    path: '/:pathMatch(.*)*',
    meta: { authRequired: true, admin: true },
    component: () => import("./views/dashboard")
  },
];